<script setup lang="ts">
import { useVModel } from '@vueuse/core'

const props = withDefaults(
  defineProps<{
    modelValue?: string | number
    placeholder?: string
    icon?: string
    disabled?: boolean
    required?: boolean
    dataItems?: (string | number | Record<string, string | number>)[]
    dataItemKey?: string
    valueField?: string
    textField?: string
  }>(),
  {
    modelValue: '',
    disabled: false,
  },
)
const emit = defineEmits<{ (...args: any): void }>()
const select = useVModel(props, 'modelValue', emit, { passive: true })

if (!select.value) {
  select.value = ''
}
</script>

<template>
  <div
    class="n-text-input n-bg-base focus-within:n-focus-base focus-within:border-context rounded px-2 py-1 border n-border-base flex items-center"
  >
    <slot name="icon">
      <NIcon v-if="icon" :icon="icon" class="mr-0.4em text-1.1em op50" />
    </slot>

    <select
      v-model="select"
      class="!outline-none flex-auto n-bg-base w-full h-6"
      :required="required"
      :disabled="disabled"
    >
      <template v-if="placeholder && !modelValue">
        <option value="" disabled selected>
          {{ placeholder }}
        </option>
      </template>

      <option
        v-for="(item, index) in dataItems"
        :key="item[dataItemKey ?? valueField] ?? index"
        :value="item[valueField] ?? item"
        :selected="(item[valueField] ?? item) === modelValue"
      >
        {{ item[textField] ?? item }}
      </option>
    </select>
  </div>
</template>
