<script setup lang="ts">
import { useField } from 'vee-validate'
const props = withDefaults(
  defineProps<{
    modelValue?: string | number
    placeholder?: string
    icon?: string
    disabled?: boolean
    required?: boolean
    type?: string
    name: string
    id: string
    label?: string
    lazy?: boolean
    rules?: string | Record<string, boolean>
    dataItems?: (string | number | Record<string, string | number>)[]
    dataItemKey?: string
    valueField?: string
    textField?: string
  }>(),
  {
    modelValue: '',
    disabled: false,
    type: 'text',
  },
)

const fieldName = toRef(props, 'name')
const { value, meta, errorMessage, handleBlur, handleChange } = useField(fieldName, props.rules, {
  label: props.label,
  validateOnValueUpdate: false,
})

const focused = ref(false)
const validationListeners = computed(() => {
  const listeners = {
    blur: (event: FocusEvent) => {
      handleBlur(event)
      focused.value = false
    },
    change: (event: InputEvent, validate = true) => {
      handleChange(event, validate)
    },
    input: (event: InputEvent, validate = true) => {
      handleChange(event, validate)
    },
  }

  if (!errorMessage.value && props.lazy) {
    return {
      ...listeners,
      input: (event: InputEvent) => listeners.input(event, false),
    }
  }

  return listeners
})
</script>

<template>
  <div class="n-form-field">
    <div>
      <NLabel :for="id" :n="errorMessage ? 'text-invalid' : 'text-label'">
        {{ label }}
      </NLabel>

      <NSelect
        v-bind="props"
        :model-value="value ?? modelValue"
        :n="errorMessage ? 'rose4 dark:red4 border-invalid' : ''"
        v-on="validationListeners"
      />
    </div>

    <NLabel v-if="errorMessage" :id="`${id}-error`" role="alert" n="text-invalid" class="mt1">
      {{ errorMessage }}
    </NLabel>
  </div>
</template>
